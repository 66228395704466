<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-2">
        {{ getValueFromGivenObjectByKey(order, 'id') }}
        —
        {{ getValueFromGivenObjectByKey(assetItem, 'product.sku') }}
        -
        {{ getValueFromGivenObjectByKey(assetItem, 'product.name') }}
      </h3>
    </portal>
    <div>
      <received-quote-form v-if="order.id" />
      <skelton v-else />
    </div>
  </div>
</template>

<script>
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import ReceivedQuoteForm from '../components/create/received-quote/Form.vue'
import Skelton from '../components/create/received-quote/components/Skeleton.vue'

// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'RequestServiceQuote',
  components: {
    Skelton,
    QuoteStatusBar,
    ReceivedQuoteForm,
  },
  computed: {
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  created() {
    this.isLoading = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [])
    this.$store.commit(`${this.MODULE_NAME}/SET_OTHER_CHARGES`, [])
    this.$store.commit(`${this.MODULE_NAME}/SET_SERVICES_PERFORMED`, [])

    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data }) => {
      const order = data

      this.$store.dispatch(`${this.MODULE_NAME}/getTestDamagedAsset`, { id: data.inventory_item_damaged.inventory_item_id })
        .then(({ data: { data } }) => {
          this.$store.commit(`${this.MODULE_NAME}/GET`, { ...order, attachments: [...data.attachments, ...order.attachments] })

          this.$store.commit(`${this.MODULE_NAME}/SET_NOTES_FORM`, {
            internal_order_reference: order.internal_order_reference,
            internal_notes: order.internal_notes,
          })
          this.$store.commit(`${this.MODULE_NAME}/SET_OTHER_CHARGES`, order.other_charges.map(charge => ({ ...charge, tax: order.customer?.customer_detail?.tax_rule.value ?? 0 })) ?? [])
          this.$store.commit(`${this.MODULE_NAME}/SET_SERVICES_PERFORMED`, order.service_performed.map(charge => ({ ...charge, tax: order.customer?.customer_detail?.tax_rule.value ?? 0 })) ?? [])

          this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [...data.attachments, ...order.attachments])
        })
        .finally(() => {
          this.isLoading = false
        })
    })
  },
  methods: { getValueFromGivenObjectByKey },
  setup() {
    const {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    }
  },
}
</script>
